import { css } from "aphrodite";
import OrderSummary from "billing/components/OrderSummary";
import OrderSummaryCopy from "billing/components/OrderSummaryCopy";
import ProductSelectionList from "billing/components/ProductSelectionList";
import SuccessModal from "billing/components/SuccessModal";
import OrderErrorDialog from "billing/components/dialogs/OrderErrorDialog";
import PaymentModal from "billing/components/dialogs/PaymentModal";
import MobilePaymentError from "billing/exceptions/MobilePaymentError";
import useBillingOptions from "billing/hooks/useBillingOptions";
import useSubmitButtonStatus from "billing/hooks/useSubmitButtonStatus";
import { FullPageOverlay } from "billing/layouts/FullPageOverlay";
import { PrivacyLink, SalesPhoneLink, styles, SupportEmailLink } from "billing/lib";
import "billing/lib/stripe.css";
import _ from "lodash";
import { showAppDeprecated } from "mobile/mobileAppUpdate";
import { getPlatform, isFullWeb, isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import { usePaymentMethod, useUpdateSubscription } from "collection/graphql/subscription";
import useReleaseFeature from "hooks/useReleaseFeature";
import useWindow from "hooks/useWindow";
import {
  BILLING_PAGE_USER_CANCELLED,
  SUBSCRIPTION_SAVE_END,
  SUBSCRIPTION_SAVE_ERROR,
  TIER_SELECTION_CONFIRM_ORDER,
  TIER_SELECTION_PAYMENT_MODAL_CANCEL,
  TIER_SELECTION_PAYMENT_MODAL_OPEN,
  TIER_SELECTION_PLAN_WELCOME_MODAL_OPEN,
  TIER_SELECTION_PLAN_WELCOME_START,
} from "lib/metrics/events";

import { Button } from "components/fl-ui";
import { GroupedButtons } from "components/fl-ui/Buttons";
import CreditCardIcon from "components/fl-ui/CreditCardIcon";
import { Checkbox } from "components/fl-ui/Form";
import { Logo } from "components/fl-ui/Icons";
import { Cluster, Stack } from "components/fl-ui/Layout";
import LoadingDialog from "components/fl-ui/LoadingDialog";
import { BaseElement } from "components/fl-ui/common";

const CheckoutPage = ({ defaultZipCode }) => {
  const preventBilling = useReleaseFeature("disable-billing-page");
  const win = useWindow();
  const form = useFormContext();
  const [billingInterval, coupon, selectedPlanId] = form.watch(["billingInterval", "coupon", "selectedPlanId"]);
  const { billingOptions, offerYearlyBilling, showBillingOptions } = useBillingOptions();
  const isSubmitDisabled = !useSubmitButtonStatus();
  const { cardExpiryCopy, hasValidCard, paymentMethod } = usePaymentMethod().data;
  const navigate = useNavigate();

  const onCancel = () => navigate("/fields", { replace: true });

  const [dialogId, setDialogId] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const closeDialog = () => {
    if (dialogId === "payment") {
      TIER_SELECTION_PAYMENT_MODAL_CANCEL.track();
    }

    setSaveError(null);
    setDialogId(null);
  };

  const handlePaymentModalOpen = () => {
    TIER_SELECTION_PAYMENT_MODAL_OPEN.track();
    setDialogId("payment");
  };

  const handleReview = () => {
    if (hasValidCard || isNative()) {
      processSubscription();
    } else {
      handlePaymentModalOpen();
    }
  };

  const { updateSubscription } = useUpdateSubscription();

  const checkIfBillingSupported = async () => {
    const billingNotSupported = isNative() && !(await isFullWeb());

    if (billingNotSupported) {
      navigate("/settings/billing", { replace: true });
      showAppDeprecated(
        "Please update to the most recent mobile version to access the billing page. Subscriptions are not supported on this version.",
      );
    }
  };
  checkIfBillingSupported();

  /**
   * @param {object} [metadata={}]
   * @param {object} [metadata.stripeToken]
   * @param {string} [metadata.stripeToken.id]
   * @param {string} [metadata.zipcode]
   * @return {Promise}
   */
  const processSubscription = async (metadata = {}) => {
    TIER_SELECTION_CONFIRM_ORDER.track(_.pick(metadata, ["zipcode"]));
    const hasStripeToken = !!metadata.stripeToken;

    /** @type {OrderDescriptor} */
    const orderDescriptor = {
      billingInterval,
      coupon,
      productIds: [selectedPlanId],
    };
    if (hasStripeToken) {
      orderDescriptor.stripeToken = metadata.stripeToken;
    }

    try {
      setDialogId("processing");

      await updateSubscription(orderDescriptor);
      SUBSCRIPTION_SAVE_END.track();

      if (!isNative()) {
        TIER_SELECTION_PLAN_WELCOME_MODAL_OPEN.track();
        setDialogId("success");
      } else {
        if (hasStripeToken) {
          TIER_SELECTION_PLAN_WELCOME_START.track();
        }
        onSuccess();
      }
    } catch (error) {
      if (error.cause instanceof MobilePaymentError && error.cause.options?.userCancelled) {
        BILLING_PAGE_USER_CANCELLED.track({
          platform: getPlatform(),
          response: error.cause,
        });
        setSaveError(null);
        setDialogId(null);
        return;
      }

      setSaveError(error);
      setDialogId("error");
      SUBSCRIPTION_SAVE_ERROR.track({
        error,
        orderDescriptor,
      });
    }
  };

  const onSuccess = () => {
    win.location = "/";
  };

  if (preventBilling) {
    navigate("/settings/billing", { replace: true });
    return null;
  }

  return (
    <FullPageOverlay onCancel={onCancel}>
      <BaseElement display="flex" justifyContent="center">
        <Logo />
      </BaseElement>

      <Row>
        <Col className={css(styles.checkOutHeader)} xs>
          {offerYearlyBilling && <p>Save up to 20% when you choose yearly billing</p>}

          {showBillingOptions && (
            <Cluster data-cy="billingOptions">
              <GroupedButtons
                onClick={({ value }) => form.setValue("billingInterval", value)}
                options={billingOptions}
                selected={billingOptions.find(({ id }) => id === billingInterval)}
              />
            </Cluster>
          )}
        </Col>
      </Row>

      <ProductSelectionList />

      <Row>
        <Col xs={12} md={6}>
          <Stack>
            <OrderSummary />

            {hasValidCard && !isNative() && (
              <div className={css(styles.borderedContainer)}>
                <div className={css(styles.bottom_line, styles.pos_formRow)}>
                  <h3 className={css(styles.product_title)}>Payment method</h3>
                </div>

                <div className={css(styles.pos_summary_text_marginTop)}>
                  <CreditCardIcon
                    cardType={paymentMethod.brand.toLowerCase()}
                    className={css(styles.pos_miniMargins)}
                  />
                  <span className={css(styles.pos_cardDetail)}>
                    {`${paymentMethod.brandName} ending in ${paymentMethod.last4} • Expires ${cardExpiryCopy}`}
                  </span>
                  <Button className={css(styles.changeButton)} color="primary" link onClick={handlePaymentModalOpen}>
                    Change
                  </Button>
                </div>
              </div>
            )}
          </Stack>
        </Col>

        <Col xs={12} md={6}>
          <Stack>
            <OrderSummaryCopy />

            <label className={css(styles.pos_agree_label)} htmlFor="terms">
              <Checkbox
                data-cy="termsCheckbox"
                id="terms"
                name="terms"
                onChange={({ target: { checked } }) => form.setValue("agreedToTerms", checked)}
                checked={form.watch("agreedToTerms")}
              />
              <strong>
                {`I agree to Bushel's `}
                <PrivacyLink>policies, terms and conditions</PrivacyLink>
              </strong>
            </label>

            <div className={css(styles.pos_buttonContainer)}>
              <Button
                className={css(styles.pos_submitButton)}
                color="primary"
                disabled={isSubmitDisabled}
                onClick={handleReview}
                size="lg"
                data-cy="orderSubmitButton"
              >
                {hasValidCard || isNative() ? "Confirm changes" : "Enter payment method"}
              </Button>
              <Button className={css(styles.cancelButton)} color="primary" link onClick={onCancel} size="lg">
                Cancel
              </Button>
            </div>

            <p className={css(styles.pos_summary_text)}>
              Have questions? You can reach us at <SalesPhoneLink /> or <SupportEmailLink />.
            </p>
          </Stack>
        </Col>
      </Row>

      {dialogId === "payment" && (
        <PaymentModal
          defaultZipCode={defaultZipCode}
          onClose={closeDialog}
          onCardUpdate={() => setDialogId(null)}
          onTokenReceipt={processSubscription}
        />
      )}
      {dialogId === "processing" && <LoadingDialog dialogMessage="Your order is processing..." />}
      {dialogId === "success" && <SuccessModal onClose={onSuccess} />}
      {dialogId === "error" && <OrderErrorDialog actionType="save" error={saveError} onClose={closeDialog} />}
    </FullPageOverlay>
  );
};

CheckoutPage.propTypes = {
  defaultZipCode: PropTypes.string.isRequired,
};

export default CheckoutPage;
